import React, { createContext, useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";

const StoreValueContext = createContext();

export const useItemContext = () => {
  return useContext(StoreValueContext);
};

export function StoreItemContext({ children }) {
  // Cart States
  const [isItemAdded, setIsItemAdded] = useState(() => {
    const parsedElement = JSON.parse(localStorage.getItem("IS-ITEM-ADDED"));
    return parsedElement || false;
  });
  const [smartCardQuantity, setSmartCardQuantity] = useState(() => {
    const parsedItem = JSON.parse(localStorage.getItem("CARD-QUANTITY"));
    return parsedItem || 0;
  });
  const [earbudsQuantity, setEarbudsQuantity] = useState(() => {
    const savedItem = JSON.parse(localStorage.getItem("EARBUD-QUANTITY"));
    if (savedItem !== null) {
      return savedItem;
    } else {
      return 0;
    }
  });
  const [cartQuantity, setCartQuantity] = useState(() => {
    const saved = JSON.parse(localStorage.getItem("MY-CART-QUANTITY"));
    return saved || 0;
  });
  const [cartItems, setCartItems] = useState(() => {
    const initialValue = JSON.parse(localStorage.getItem("MY-CART-ITEMS"));
    return initialValue || [];
  });

  // Update and add all cart quantity
  useEffect(() => {
    const totalQuantity = cartItems.length
    setCartQuantity(totalQuantity);
  }, [cartItems]);

  // Increase Cart Quantities
  const handleCartIncrement = () => {
    setCartQuantity((prevQuantity) => prevQuantity + 1);
  };
  // Cart reduce item button
  const handleDelete = (item) => {
    if (item.quantity > 0) {
      const updatedItems = cartItems.filter((val) => val.id !== item.id);
      setCartItems(updatedItems);
      localStorage.setItem("MY-CART-ITEMS", JSON.stringify(updatedItems));
    }
  };

  // Cart Total items
  const totalPrice = cartItems.reduce((accumulator, item) => {
    const multipliedPrice =  parseFloat((item.price).replace(/[^0-9.-]+/g, "")) * parseInt(item.quantity);
    return accumulator + multipliedPrice;
  }, 0);
  const cartTotal = totalPrice.toLocaleString({mixFractionDigits:2});

  // Local storage
  useEffect(() => {
    localStorage.setItem("MY-CART-ITEMS", JSON.stringify(cartItems));
    localStorage.setItem("MY-CART-QUANTITY", JSON.stringify(cartQuantity));
    localStorage.setItem("EARBUD-QUANTITY", JSON.stringify(earbudsQuantity));
    localStorage.setItem("CARD-QUANTITY", JSON.stringify(smartCardQuantity));
    localStorage.setItem("IS-ITEM-ADDED", JSON.stringify(isItemAdded));
  }, [
    cartItems,
    cartQuantity,
    earbudsQuantity,
    smartCardQuantity,
    isItemAdded,
  ]);

  const contextValue = {
    cartQuantity,
    setCartQuantity,
    cartItems,
    setCartItems,
    handleCartIncrement,
    smartCardQuantity,
    setSmartCardQuantity,
    earbudsQuantity,
    setEarbudsQuantity,
    cartTotal,
    handleDelete,
    isItemAdded,
    setIsItemAdded,
    totalPrice,
  };

  return (
    <StoreValueContext.Provider value={contextValue}>
      {children}
    </StoreValueContext.Provider>
  );
}

StoreItemContext.propTypes = {
  children: PropTypes.node.isRequired,
};
