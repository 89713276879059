import { useState, useEffect } from "react";

import axios from "axios";

export default function useFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(url);
        setData(response.data);
        setError(null);
      } catch (error) {
        const { request, response } = error;
        setData(null);
        if (response) {
          const { message } = response.data;
          // const status = response.status;
          setError(message);
        } else if (request) {
          //request sent but no response received
          setError("server time out");
        } else {
          // Something happened in setting up the request that triggered an Error
          setError("opps! something went wrong while setting up request");
          // return { message: "opps! something went wrong while setting up request" };
        }
      } finally {
        setLoading(false);
      }
    }
    getData();
  }, [url]);

  return { data, loading, error };
}

export function useApi(url, options = null) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function getData() {
    try {
      const response = await axios.post(url, options);
      setData(response.data);
      setError(null);
    } catch (error) {
      if (error instanceof AxiosError) setError(error.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  }

  getData();

  return { data, loading, error };
}
