import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../../utils/services";

export function likedProductHandler(product) {
  const [isLiked, setIsLiked] = useState([]);

  useEffect(() => {
    if (product?.sucess) {
      const likedProducts = Array(product?.count).fill({
        liked: 0,
        status: false,
        product_id: null,
      });
      const existingLikedProducts = localStorage.getItem("likedProducts");
      if (!existingLikedProducts) {
        localStorage.setItem("likedProducts", JSON.stringify(likedProducts));
        setIsLiked(likedProducts);
      } else {
        // Use the existing data from localStorage
        setIsLiked(JSON.parse(existingLikedProducts));
      }
    } else if (!product) {
      setIsLiked([]);
    }
  }, [product]);
  return { isLiked, setIsLiked };
}


export async function toggleLike(setIsLiked, index, product_id) {
  try {
    setIsLiked((prev) => {
      if (!prev) return prev;
      const updatedLikedProduct = prev.map((item, i) =>
        i === index
          ? {
              ...item,
              status: !item.status,
              liked: item.liked === 0 ? 1 : 0,
              product_id,
            }
          : item
      );
      localStorage.setItem("likedProducts", JSON.stringify(updatedLikedProduct));
      return updatedLikedProduct;
    });

    const { liked } = JSON.parse(localStorage.getItem("likedProducts"))[index];

    if (liked === 1) {
      // Like the product
      const res = await axios.get(
        `${BASE_URL}/productunlike/${product_id}`
      );ß
      if (res.status === 200) {
        console.log("unliked a product");
      } else {
        console.log("Failed to unlike the product:", res.data);
      }
    } else {
      // Unlike the product
      const res = await axios.get(
        `${BASE_URL}/productlike/${product_id}`
      );
      if (res.status === 200) {
        console.log("liked a product");
      } else {
        console.log("Failed to like the product:", res.data);
      }
    }
  } catch (error) {
    console.error("Error toggling like:", error);
  }
}


likedProductHandler.propTypes = {
    product: PropTypes.any,
};

toggleLike.propTypes = {
    setIsLiked: PropTypes.func,
    index: PropTypes.number,
    product_id: PropTypes.string
}