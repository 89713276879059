import React, { useState } from "react";
import PropTypes from "prop-types";
import { IKContext, IKImage, IKVideo } from "imagekitio-react";

const urlEndpoint = "https://ik.imagekit.io/Adetimmy/";
const transform = [
  {
    height: "300",
    width: "500",
  },
];

export const Image = (props) => {
  return (
    <IKContext
      publicKey="public_YgXrMRmKMci3MDsWuZd/El7ephs="
      urlEndpoint={urlEndpoint}
    >
      <IKImage {...props} lqip={[{ active: true }]} loading="lazy" />
    </IKContext>
  );
};

Image.propTypes = {
  props: PropTypes.any,
};

export const ImageWithLoader = ({ src, alt, className, loading='lazy', onClick }) => {
  // Track the loading state and error state
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // Handle image loading
  const handleLoad = () => {
    setIsLoading(false); // Image loaded successfully
  };

  // Handle image error (e.g., if it fails to fetch)
  const handleError = () => {
    setIsLoading(false); // Stop showing loader
    setHasError(true); // Set error to true
  };

  return (
    <>
      {/* Show the loader if the image is still loading */}
      {isLoading && !hasError && (
        <div className="w-full h-full flex justify-center items-center ">
          <div className="form-loading" />
        </div>
      )}

      {/* Show the image if it has loaded, else show the alt text */}
      {!hasError ? (
        <img
          src={src}
          alt={alt}
          onLoad={handleLoad}
          onError={handleError}
          loading={loading}
          onClick={onClick}
          className={!isLoading && className}
          // style={{ display: isLoading ? 'none' : 'block' }} // Hide image until it loads
        />
      ) : (
        <div className="error">{alt}</div> // Show alt text if error occurs
      )}
    </>
  );
};
ImageWithLoader.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  loading: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};
export default ImageWithLoader;
