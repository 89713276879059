import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Description from "./Description";
import { QuantitySelector } from "./QuantitySelector";
import { useItemContext } from "../components/storeItem/StoreContext";

export const ProductDetails = ({ item, quantity, onQuantityChange }) => {
  const { cartItems, setCartItems, setIsItemAdded } = useItemContext();
  const product = JSON.parse(localStorage.getItem("user-Item")).flatMap(
    (val) => val
  );

  const handleAddToCart = (item) => {
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);

    if (existingItem) {
      const updatedCartItems = cartItems.map((cartItem) => {
        if (cartItem.id === item.id) {
          return { ...cartItem, quantity };
        }
        return cartItem;
      });
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, item]);
    }
    setIsItemAdded(true);
  };
  return (
    <div className="storeItem__info lg:!pr-28 !text-[#D7D7D7]">
      <h2 className="storeItem__info-name uppercase">
        {item.name === "AXAO CARD" ? "Digital Card" : item.name}
      </h2>
      <p className="storeItem__info-description !-mt-3 !font-extralight !text-sm !capitalize">
        {item.desc}
      </p>
      <QuantitySelector
        quantity={quantity}
        onQuantityChange={onQuantityChange}
      />
      <p className="storeItem__product-about !font-extralight !text-lg">About</p>
      {item?.description?.itemInfo ? (
        <ul className="!list-disc ml-3 -mt-5 font-extralight tracking-wide text-sm text-white">
          {item?.description?.itemInfo.map((info) => (
            <li key={info}>{info}</li>
          ))}
        </ul>
      ) : (
        <p className="storeItem__product-info !-mt-4">{item.itemInfo}</p>
      )}
      {/* Controls if available */}
      {item?.description?.controls ? (
        <ul className="!list-disc ml-3 mt-1 font-extralight tracking-wide text-sm text-white">
          <li>Controls:</li> <br />
          {item?.description?.controls.map((info) => (
            <li className="ml-3 -mt-1 -translate-y-3" key={info}>
              {info}
            </li>
          ))}
        </ul>
      ) : null}
      {/* features if available */}
      {item?.description?.features ? (
        <ul className="!list-disc ml-3 -mt-3 font-extralight tracking-wide text-sm text-white">
          <li>Features:</li> <br />
          {item?.description?.features.map((info) => (
            <li className="ml-3 -mt-1 -translate-y-3" key={info}>
              {info}
            </li>
          ))}
        </ul>
      ) : null}
      {/* <p className="storeItem__product-info !-mt-4">{item.itemInfo}</p> */}
      <div className="storeItem__cart-container">
        <p className="storeItem__price !font-extralight !tracking-tight">
          {item.discounted_amount}
        </p>
        <div className="storeItem__cart-wrapper">
          <Link to="/products/cart">
            <button
              className="storeItem__cart cu"
              disabled={quantity < 1}
              onClick={() => {
                handleAddToCart(product[0]);
              }}
            >
              Add to Cart
            </button>
          </Link>
        </div>
      </div>
      <Description item={item.description} />
    </div>
  );
};

ProductDetails.propTypes = {
  item: PropTypes.any,
  quantity: PropTypes.any,
  onQuantityChange: PropTypes.any,
};
