import { useMutation, useQuery } from "react-query";
import { fetchApi, postApi } from "./services";
import { handleError } from "./toast";

export const usePostApi = (url) => {
  return useMutation({
    mutationFn: (body) => postApi(url, body), // Accepts `body` directly here
    onSettled: (_, error) => {
      if (error) {  // Corrected instance check
        handleError(error.message);
      }
    },
  });
};

export const useFetchApi = (url) => {
  return useQuery({
    queryKey:['axao'],
    queryFn: () => fetchApi(url)
  })
}
