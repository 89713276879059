import axios from "axios";

export const BASE_URL = "https://axaobe-1.onrender.com/api/v1"
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const postApi = async (url, option) => {
  try {
    const res = (await axiosInstance.post(url, option)).data;
    if (res.success) {
      return { data: res, error: null };
    }
    return { data: null, error: res };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

const fetchApi = async (url) => {
  try {
    const res = (await axiosInstance.get(url)).data;
    if (res.success || res.sucess) {
      return { data: res, error: null };
    }
    return { data: null, error: res };
  } catch (error) {
    return {
      data: null,
      error: error.response ? error.response.data : error.message,
    };
  }
};

export { postApi, fetchApi };
