import toast from "react-hot-toast";

/**
 * It takes a message as an argument and displays it as a toast notification
 * @param message - The message to be displayed
 */
export const handleError = (message) => {
  if (message)
    toast.error(message, {
      duration: 4000,
      position: "top-center",
    });
};
