import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cartIcon from "../../../../../assets/icons/cartIcon.svg";
import "../../store.css";
import PropTypes from "prop-types";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ImageWithLoader from "../../../../../common/components/product/ImageKit";
import { TiArrowSortedDown } from "react-icons/ti";
import { handleError } from "../../../../../utils/toast";
import { useFetchApi } from "../../../../../utils/queries";
import { CiHeart } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";
import { likedProductHandler, toggleLike } from "./likedProductHandler";

export default function StoreProducts({ isLandingPage }) {
  const { data, isLoading: loading } = useFetchApi("/product");
  const product = data?.data;
  const { isLiked, setIsLiked } = likedProductHandler(product);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const scrollContainerRef = useRef(null);
  const scroll = (direction) => {
    const { current } = scrollContainerRef;
    if (current) {
      const scrollAmount = 50;
      if (direction === "left") {
        current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };
  if (data?.error) {
    handleError(data?.error);
  }

  return (
    <>
      {/* Store products */}
      <section className="relative w-full h-full !-mt-10 min-h-max store__container">
        <button
          className="absolute store__btn shadow-sm left-0 top-1/2 transform -translate-y-1/2 z-10  h-4/5  w-12 rounded-l-[20px] bg-black/0 hover:bg-black/5 focus:outline-none flex justify-center items-center"
          onClick={() => scroll("left")}
        >
          <IoIosArrowBack className="text-white/50 shadow-black" />
        </button>
        {/* <div className=" gap-2 min-h-[200px] overflow-x-auto scroll-smooth"/> */}
        <div
          className={`w-full ${
            pathname === "/products/store"
              ? "grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-[20px] md:gap-y-[80px] xl:gap-y-[104px] gap-x-[10px] md:gap-x-[35px] xl:gap-x-[80px]"
              : "flex space-x-4 max-md:space-x-5 scrollable-container"
          } overflow-x-auto scroll-smooth mt-12 pb-12 gap-2`}
          ref={scrollContainerRef}
        >
          {/* ELoading state */}
          {loading && (
            <div className="flex justify-center items-center w-full h-auto py-3">
              <div className="form-loading" />
            </div>
          )}

          {!product && !loading && (
            <div className="text-center w-full flex justify-center">
              <p>No products available in &ldquo;STORE&rdquo; or failed to load.</p>
            </div>
          )}

          {product &&
            !loading &&
            product?.product?.map((product, index) => {
              return (
                <div
                  onClick={() =>
                    product.stock_quantity >= 1 &&
                    navigate(`/products/store/${product.id}`)
                  }
                  // to={
                  //   product.stock_quantity >= 1 &&
                  //   `/products/store/${product.id}`
                  // }
                  key={product.id}
                  className={`w-full min-w-[300px] max-md:min-w-[200px] max-sm:min-w-[180px] bg-gradient-to-t from-[#000] from-10% via-[#0B0B0B] via-55% max-md:via-65% to-[#101010] rounded-[20px] overflow-hidden relative p-[12px] h-auto last:mr-36 ${
                    product.stock_quantity < 1
                      ? "cursor-not-allowed"
                      : "store-me  cursor-pointer"
                  } product__container`}
                >
                  {product.status === "new" && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E] z-10">
                      New
                    </div>
                  )}
                  {product.status === "hot" && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E] z-10">
                      Hot🔥
                    </div>
                  )}
                  {product.stock_quantity < 1 && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E] z-10">
                      Sold Out
                    </div>
                  )}
                  {product.status === "preorder" && (
                    <div className="rounded-[12px] text-xs px-2 py-1 text-slate-200 absolute top-5 left-5 bg-[#1E1E1E]/50 z-10">
                      Pre-Order
                    </div>
                  )}
                  <div
                    className="rounded-lg text-xs text-slate-200 absolute top-5 right-5 z-10 heart"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {/* <img src={heartIcon} alt="" /> */}
                    {(isLiked[index]?.status && isLiked[index]?.product_id === product.id )|| null ? (
                      <FaHeart
                        className="text-red-600 likedHeart"
                        onClick={() =>
                          toggleLike(setIsLiked, index, product?.id)
                        }
                      />
                    ) : (
                      <CiHeart
                        className="text-base"
                        onClick={() =>
                          toggleLike(setIsLiked, index, product?.id)
                        }
                      />
                    )}
                  </div>
                  <figure className="w-full object-cover">
                    <ImageWithLoader
                      src={product.images[0]}
                      alt={product.name}
                      className="opacity-80 z-0 w-full object-cover rounded-lg max-sm:h-[125px] xl:h-[250px] h-[cal(200px-5%)]"
                    />
                    <figcaption className="px-2">
                      <span className="block text-xl max-md:text-lg my-3 font-extralight tracking-tight text-[#D7D7D7]">
                        {product.name}
                      </span>

                      {/* Price */}
                      <div className="mt-7 mb-5 max-md:mb-3 w-full overflow-x-hidden relative flex items-center justify-between max-sm:gap-4">
                        <div className="flex items-center w-1/2 max-w-max bg-transparent border-[.1px] border-solid border-white/30 px-1.5 max-sm:!px-1 py-2 rounded-lg ">
                          <img
                            src={cartIcon}
                            alt="cartIcon"
                            className="absolute left-3 top-4 transition-all duration-1000 ease-in-out opacity-0"
                          />
                          <span className="price font-extralight tracking-tight transition-all duration-1000 ease-in-out">
                            {product.discounted_amount}
                          </span>
                        </div>
                        {/* discount and percentage container*/}
                        <div className="w-1/2 flex flex-col items-start">
                          <div className="line-through text-white font-extralight max-sm:text-sm text-[20px]">
                            {product.price}
                          </div>
                          <div className="flex gap-3 justify-between items-center text-[#87B8F0] text-xs font-extralight">
                            <p className="max-sm:text-[10px]">Save </p>
                            <p className="text-[10px] sm:flex items-center hidden">
                              <TiArrowSortedDown /> {product.discount}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              );
            })}
        </div>

        <button
          className="absolute store__btn right-0 top-1/2 transform -translate-y-1/2 z-10 h-4/5  w-12 rounded-r-[20px] bg-black/0 hover:bg-black/5 focus:outline-none flex justify-center items-center"
          onClick={() => scroll("right")}
        >
          <IoIosArrowForward />
        </button>
      </section>
      {/* Axoa store products ends */}
    </>
  );
}

StoreProducts.propTypes = {
  isLandingPage: PropTypes.bool,
};
